import { initializeApp} from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyAqMF1rml2m2w2GQMwHBIFDaw94wxa_pic",
  authDomain: "meta-chatgpt.firebaseapp.com",
  projectId: "meta-chatgpt",
  storageBucket: "meta-chatgpt.appspot.com",
  messagingSenderId: "239303283826",
  appId: "1:239303283826:web:f71a33ed198d60380c9eeb",
  measurementId: "G-R4D05MGRQZ"
};


const app = initializeApp(firebaseConfig);


export default app;

